header {
    background: #ffffffd3;
    display: flex;
    width: 100%;
    z-index: 1000;
    backdrop-filter: blur(10px);
}

header .sem-logo {
    width: 120px;
    margin: 20px 0px 10px 20px;
}

header .navigation {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

header .navigation li {
    display: inline-flex;
}


header .navigation li a {
    display: block;
    color: #000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    transition: 1s;
}

header .navigation li a:hover {
    color: #979797;
}

.navigation .icon {
    position: relative;
    top: -2px;
}

.toggle-rh {
    display: none !important;
}

.d-none {
    display: none;
    transition: 1s;
}

/* responsive */

@media screen and (max-width: 955px) {
    header {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    header .navigation  {
        width: 100%;
        flex-direction: column;
        display: none;
        min-height: 100vh;
    }

    header .navigation.active {
        display: flex;
    }

    header .navigation li {
        display: block;
        width: 100%;
        /* border-top: 0.1px solid rgba(255, 255, 255, 0.048); */
        margin: 20px auto;
        font-size: 17px;
    }

    header .navigation li.btn {
        width: 80%;
    }

    header .sem-logo {
        width: 100px;
        margin: 20px 0 10px 20px;
    }

    .toggle-rh {
        display: block !important;
        position: absolute;
        top: 1.7rem;
        right: 1.7rem;
        cursor: pointer;
        width: 30px;
    }
}

@media screen and (max-width: 600px) {
    header .sem-logo {
        margin: 20px 0 10px 20px;
    }
}